<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base" v-if="is_export">
      <vx-card title="Export Option">
        <div class="vx-row mb-5">
          <div class="vx-col w-full">
              <small>Option</small>
              <div class='mt-1'>
                  <input class='mr-2' type='radio' value='daily' vs-value='daily' name='export_option' v-model="export_option">
                  <span class='mr-6'>Daily</span>
                  <input class='mr-2' type='radio' value='weekly' vs-value='weekly' name='export_option' v-model="export_option">
                  <span class='mr-6'>Weekly</span>
                  <input class='mr-2' type='radio' value='monthly' vs-value='monthly' name='export_option' v-model="export_option">
                  <span class='mr-6'>Monthly</span>
                  <input class='mr-2' type='radio' value='yearly' vs-value='yearly' name='export_option' v-model="export_option">
                  <span class='mr-6'>Yearly</span>
                </div>
              </div>
        </div>
        <div v-if="export_option == 'daily'" class="vx-row mb-5">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
            <small class="ml-1">Shipment Day</small>
            <flat-pickr :config="flatPickrConfig" class="w-full" v-model="shipment_day"></flat-pickr>
          </div>
        </div>
        <div class="vx-col w-full flex mt-3">
          <vs-button @click="exportExcel()" color="success">Export Excel</vs-button>&nbsp;
          <vs-button color="warning" @click="openExportOption(false)">Hide</vs-button>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <vx-card title="Sparepart Shipments">
        <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
          <template slot="header">
            <vs-button @click="openExportOption(true)" color="success">Option Export Excel</vs-button>
          </template>
          <template slot="thead">
            <vs-th sort-key="shipment_date">Date Shipment</vs-th>
            <vs-th sort-key="job_order_code">Job Order No.</vs-th>
            <vs-th sort-key="delivery_order_code">Shipment No.</vs-th>
            <vs-th sort-key="customer_name">Customer</vs-th>
            <vs-th sort-key="sender_name">Shipper</vs-th>
            <vs-th sort-key="receiver_name">Consignee</vs-th>
            <vs-th sort-key="car_sparepart_name">Sparepart</vs-th>
            <vs-th sort-key="qty">Quantity</vs-th>
            <vs-th sort-key="delivery_status_name">Status</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.shipment_date">{{tr.shipment_date}}</vs-td>
              <vs-td label="Job Order No." :data="tr.job_order_code">{{tr.job_order_code}}</vs-td>
              <vs-td label="Shipment No." :data="tr.delivery_order_code">{{tr.delivery_order_code}}</vs-td>
              <vs-td label="Customer" :data="tr.customer_name">{{tr.customer_name}}</vs-td>
              <vs-td label="Shipper" :data="tr.sender_name">{{tr.sender_name}}</vs-td>
              <vs-td label="Consignee" :data="tr.receiver_name">{{tr.receiver_name}}</vs-td>
              <vs-td label="Sparepart" :data="tr.car_sparepart_name">{{tr.car_sparepart_name}}</vs-td>
              <vs-td label="Quantity" class="text-right" :data="tr.qty">{{tr.qty}}</vs-td>
              <vs-td label="Status" :data="tr.delivery_status_name">{{tr.delivery_status_name}}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import axios from '@/axios'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
export default {
  components: {
    flatPickr
  },
  data(){
    return {
      is_export: false,
      export_option: 'daily',
      shipment_day: '',
      flatPickrConfig: {
        altFormat: 'j M Y',
        altInput: true,
        dateFormat: 'Y-m-d',
      },
      idDelete: null,
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null
    }
  },
  watch:{
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.sparepart_shipments.rows
    })
  },
  methods:{
    ...mapActions({
      dispatchIndex: 'sparepart_shipments/index'
    }),
    async getData(){
      let payload = {
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal
      }
      await this.dispatchIndex(payload)
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    },
    openExportOption(state) {
      this.is_export = state;
      this.export_option = 'daily';
      this.shipment_day = null;
    },
    async exportExcel() {
      let payload = { export_option: this.export_option };
      if(this.export_option == 'daily') {
        payload.shipment_day = this.shipment_day;
      }
      await axios.post(`operational/exports/sparepart_shipments`, payload, {
            responseType: 'blob'
        }).then((response) => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(
                new Blob([response.data])
            );
            link.setAttribute('download', 'Sparepart Shipment.xlsx');
            document.body.appendChild(link);
            link.click();
      });
    }
  },
  async mounted(){
    await this.getData()
  }
}
</script>

<style>

</style>